<template>
  <b-card
    class="card-congratulation-medal"
    img-left
    img-width="200"
    img-height="200"
    :img-src="image"
  >
    <!-- Correct top margin>-->
    <br />
    <br />
    <br />
    <h3>{{ title }}</h3>
    <b-card-text v-show="desc" class="font-small-3">
      {{ $t("lists.date") }}: {{ desc }}
    </b-card-text>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="success"
      :disabled="disableChangeList()"
      @click="updateList(list)"
    >
      {{ $t("lists.edit") }}
    </b-button>
    <b-icon-hr style="visibility: hidden; margin-right: 20px">
      &nbsp;&nbsp;&nbsp;</b-icon-hr
    >
    <b-button variant="danger" @click="deleteList(list.uuid)" :disabled="disableDeleteList()">
      {{ $t("lists.delete") }} 
    </b-button>
    <hr />
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      class="blue-button"
      variant="blue-button"
      :disabled="disableChangeList()"
      @click="showSideAddInfluencer()"
    >
    <feather-icon icon="UserPlusIcon"/>
      {{ $t("lists.addInfluencer") }}
    </b-button>

    <span id="button-csv">
      <b-button     
        class="button-csv"
        v-b-modal.modalCsv
        :disabled="disableChangeList() || processing"
      >
        <img src="@/assets/images/elements/campaign/filecsv.svg"/>
        {{ $t("lists.addListCsv") }} 
      </b-button>
    </span>

    <b-button
      class="blue-button ml-1"
      variant="blue-button"
      href="https://brandme.la/wp-content/template_listas.csv"
    >
    <b-icon icon="cloud-arrow-down"/>
      {{ $t("lists.downloadTemplate") }}
    </b-button>

    <b-tooltip target="button-csv" variant="danger" triggers="hover" placement="bottomright" v-if="processing">
      {{$t('lists.tooltipCsvProcess')}}
    </b-tooltip>

    <b-modal
      id="modalCsv"
      centered
      :title="$t('lists.addListText')"
      hide-footer
      @close="closeFile"
    >
      <validation-observer ref="updateProfile" #default="{ invalid }">
        <validation-provider rules="required">
          <b-form-file
            accept=".csv"
            class="mt-1"
            v-model="fileCsv"
            :placeholder="$t('lists.addListText1')"
          ></b-form-file>
        </validation-provider>
        <b-button
          :disabled="invalid"
          variant="primary"
          class="my-1"
          @click="uploadFile"
        >
        {{$t('lists.upload')}}
        </b-button>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

<script>
import { BCard, BCardText, BButton, BModal, BFormFile, BTooltip } from "bootstrap-vue";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import list_service from "@/services/lists";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import utils from '@/libs/utils'

export default {
  data() {
    return {
      fileCsv: null,
    };
  },
  methods: {
    closeFile() {
      this.fileCsv = null;
    },

    uploadFile() {

      const formData = new FormData();
      formData.set("file_csv", this.fileCsv);
      list_service.addListExcel(this.list.uuid, formData).then((res) => {
        if (res.results) {
          this.$bvModal.hide("modalCsv");
          this.$emit('rows_results',res.results)

          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('lists.addSuccess'),
              icon: "BellIcon",
              text: this.$t('lists.addSuccessText'),
              variant: "success",
            }
          },
          {
            timeout: 20000  
          });
        } else if (res.code && res.code === 'csv.BadFormat') {
          this.$swal({
            title: this.$t('lists.errorCsv'),
            text:  this.$t('lists.formatCsv'),
            imageUrl: require('@/assets/images/lists/example_csv.png'),
            imageWidth: 400,
            imageHeight: 200,
            icon: 'danger',
            customClass: {
              confirmButton: 'btn btn-danger'
            },
            heightAuto: false,
            buttonsStyling: false
          })
        } else if (res.code && res.code === 'list.Processing') {
          this.$swal({
            title: this.$t('lists.titleCsvProcess'),
            text: this.$t('lists.textCvsProcess'),
            icon: 'danger',
            customClass: {
              confirmButton: 'btn btn-danger'
            },
            heightAuto: false,
            buttonsStyling: false
          })
        }
      });
    },
    deleteList(list_uuid) {
      this.$swal({
        title: this.$t('lists.delete_confirm'),
        icon: 'warning',
        customClass: {
          confirmButton: 'btn confirm-button ',
          cancelButton: 'btn btn-outline-danger mr-1'
        },
        heightAuto: false,
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t('lists.delete_confirm_button'),
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          if (this.disableDeleteList()) return;
          this.$emit('deleteList', list_uuid);
        }
      });
    },
    disableDeleteList() {
      return !utils.hasPermission('delete_list')
    },
    updateList(list) {
      if (this.disableChangeList()) return;
      this.$emit('update:is-task-handler-sidebar-active', true);
      this.$emit('close-left-sidebar');
      this.$emit('selEditList', list);
    },
    disableChangeList() {
      return !utils.hasPermission('change_list')
    },
    showSideAddInfluencer() {
      if (this.disableChangeList()) return;
      this.$emit('update:is-add-influencer-active', true);
      this.$emit('close-left-sidebar');
    }
  },

  components: {
    BCard,
    BCardText,
    BButton,
    BModal,
    BFormFile,
    ValidationObserver,
    ValidationProvider,
    BTooltip
  },
  directives: {
    Ripple,
  },
  props: {
    title: {
      type: String,
    },
    image: {
      type: String,
    },
    desc: {
      type: String,
    },
    list: {
      type: Object,
    },
    processing: {
      type: Boolean,
      default: false
    }
  },
};
</script>
<style>
.button-csv{
  background-color: transparent !important;
  color:grey !important;
  margin-left: 15px !important;
  border: gray solid 1px !important;
}
.button-csv:focus{
  background-color: transparent !important;
  color:grey !important;
  margin-left: 15px !important;
  border: gray solid 1px !important;
}
</style>